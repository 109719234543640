/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import "@angular/material/theming";

@include mat.core();

$angular-primary: mat.define-palette(mat.$indigo-palette, 500, 100, 900);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$angular-warn: mat.define-palette(mat.$red-palette);

$angular-default-theme: mat.define-light-theme((color: (primary: $angular-primary,
                accent: $angular-accent,
                warn: $angular-warn,
            ),
            typography: mat.define-typography-config(),
        ));

@include mat.all-component-themes($angular-default-theme);

$angular-dark-theme: mat.define-dark-theme((color: (primary: $angular-primary,
                accent: $angular-accent,
                warn: $angular-warn,
            ),
            typography: mat.define-typography-config(),
        ));

.dark-theme
{
    @include mat.all-component-colors($angular-dark-theme);

    color: var(---ffffff-white) !important;
    background-color: var(---3e3f3f-greydark) !important;

    #darkModeSwitch
    {
        background-color: var(---3e3f3f-greydark) !important;
        border-color: var(---00dec7-tuerkis) !important;

        .hasomed-button-icon,
        .hasomed-button-text
        {
            color: var(---00dec7-tuerkis) !important;
        }

        &:hover
        {
            background-color: var(---00dec7-tuerkis) !important;

            .hasomed-button-icon,
            .hasomed-button-text
            {
                color: var(---3e3f3f-greydark) !important;
            }
        }
    }

    .hasomed-button
    {
        background-color: var(---3e3f3f-greydark) !important;
        border-color: var(---00dec7-tuerkis) !important;

        .hasomed-button-icon,
        .hasomed-button-text
        {
            color: var(---00dec7-tuerkis) !important;
        }

        &:hover
        {
            background-color: var(---00dec7-tuerkis) !important;

            .hasomed-button-icon,
            .hasomed-button-text
            {
                color: var(---3e3f3f-greydark) !important;
            }
        }
    }

    .hasomed-button-red
    {
        /* UI Properties */
        background: var(---f93816-redstd) 0% 0% no-repeat padding-box !important;
        border: 0 !important;

        .hasomed-button-icon,
        .hasomed-button-text
        {
            color: var(---ffffff-white) !important;
        }

        &:hover
        {
            /* UI Properties */
            background-color: var(--unnamed-color-fa6045) !important;

            .hasomed-button-icon,
            .hasomed-button-text
            {
                color: var(---ffffff-white) !important;
            }
        }
    }

    .hasomed-button-disabled
    {
        /* UI Properties */
        background: transparent 0% 0% no-repeat padding-box !important;
        border: 1px solid var(---bbbdbe-greylight2) !important;

        &:hover
        {
            /* UI Properties */
            background-color: transparent !important;
            cursor: default;

            .hasomed-button-icon,
            .hasomed-button-text
            {
                color: var(---bbbdbe-greylight2) !important;
            }
        }

        .hasomed-button-icon,
        .hasomed-button-text
        {
            color: var(---bbbdbe-greylight2) !important;
        }
    }

    .loading-header
    {
        color: var(---ffffff-white) !important;
    }

    .sidecontent
    {
        background-color: var(---3e3f3f-greydark) !important;

    }

    .content-module
    {
        background-color: var(---3e3f3f-greydark) !important;
        color: var(---ffffff-white) !important;
    }

    .content-list
    {
        background-color: var(---3e3f3f-greydark) !important;
        color: var(---ffffff-white) !important;

        mat-list-item:hover
        {
            background-color: var(---575858-greystd) !important;
        }
    }

    .mat-toolbar
    {
        background-color: var(---575858-greystd) !important;
    }

    .mat-mdc-fab.mat-primary,
    .mat-mdc-mini-fab.mat-primary
    {
        --mdc-fab-container-color: var(---00dec7-tuerkis) !important;
    }

    .mat-mdc-raised-button.mat-primary
    {
        --mdc-protected-button-container-color: var(---00dec7-tuerkis) !important;
    }

    .mdc-tab--active .tab-icon,
    .mdc-tab--active .tab-label,
    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label
    {
        color: var(---00dec7-tuerkis) !important;
    }

    .mdc-tab-indicator__content--underline
    {
        border-color: var(---00dec7-tuerkis) !important;
    }

    .dropzoneDialog
    {
        .dropzone
        {
            h3
            {
                color: var(---ffffff-white) !important;
            }

            img
            {
                filter: brightness(0) invert(1);
            }

            .uploadIcon
            {
                color: var(---ffffff-white) !important;
            }

            label
            {
                background-color: var(---00dec7-tuerkis) !important;
            }
        }
    }
}

html,
body
{
    font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: lighter;
}

body
{
    background-color: var(---fafafa-greylight5);
}

.pointer
{
    cursor: pointer;
}

.vertical-spacer
{
    flex: 1 1 auto;
}

.mdc-tab-indicator__content--underline
{
    border-color: var(---1c53f3-bluestd) !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label
{
    color: var(---1c53f3-bluestd) !important;
}

.mdc-tab--active
{
    .tab-icon
    {
        margin-right: 8px;
        color: var(---1c53f3-bluestd);
    }

    .tab-label
    {
        color: var(---1c53f3-bluestd);
    }
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary
{
    --mdc-fab-container-color: var(---1c53f3-bluestd);
}

.mat-mdc-raised-button.mat-primary
{
    --mdc-protected-button-container-color: var(---1c53f3-bluestd);
}

.mat-mdc-tab-body.mat-mdc-tab-body-active
{
    overflow-y: hidden !important;
}

.mat-mdc-tab-body-wrapper
{
    height: 100%;
}

.hasomed-button
{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 5px;
    padding: 8px 16px;
    border-radius: 8px;
    opacity: 1;

    &:hover
    {
        cursor: pointer;
    }
}

.hasomed-button-text
{
    /* UI Properties */
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    opacity: 1;
}

.hasomed-button-icon
{
    height: 24px;
    width: 24px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* UI Properties */
    font: var(--unnamed-font-style-normal) normal 300 20px/var(--unnamed-line-spacing-24) Font Awesome 6 Pro;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    opacity: 1;

    .mat-icon
    {
        width: 18px;
    }
}

.hasomed-iconbutton
{
    height: 40px;
    width: 40px;
    padding: 8px;
    /* UI Properties */
    background: var(---ececec-greylight3) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0;
    /* UI Properties */
    font: var(--unnamed-font-style-normal) normal 300 20px/var(--unnamed-line-spacing-24) Font Awesome 6 Pro;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
    text-align: left;
    opacity: 1;

    &:hover
    {
        /* UI Properties */
        background-color: var(---1c53f3-bluestd);

        .hasomed-button-icon,
        .hasomed-button-text
        {
            color: var(---ffffff-white) !important;
        }
    }

    .hasomed-button-icon
    {
        color: var(---575858-greystd);
        margin: 0;
    }
}

.hasomed-button-white
{
    /* UI Properties */
    background: var(---ffffff-white) 0% 0% no-repeat padding-box;
    padding: 7px 15px;
    border: 1px solid var(---1c53f3-bluestd);

    &:hover
    {
        /* UI Properties */
        background-color: var(---1c53f3-bluestd);
        border-color: var(---1c53f3-bluestd);

        .hasomed-button-icon,
        .hasomed-button-text
        {
            color: var(---ffffff-white);
        }
    }

    .hasomed-button-icon,
    .hasomed-button-text
    {
        color: var(---1c53f3-bluestd);
    }
}

.hasomed-button-blue
{
    /* UI Properties */
    background: var(---1c53f3-bluestd) 0% 0% no-repeat padding-box;
    border: 0;

    &:hover
    {
        /* UI Properties */
        background-color: var(---00dec7-tuerkis);

        .hasomed-button-icon,
        .hasomed-button-text
        {
            color: var(---ffffff-white) !important;
        }
    }

    .hasomed-button-icon,
    .hasomed-button-text
    {
        color: var(---ffffff-white);
    }
}

.hasomed-button-red
{
    /* UI Properties */
    background: var(---f93816-redstd) 0% 0% no-repeat padding-box;
    border: 0;

    &:hover
    {
        /* UI Properties */
        background-color: var(--unnamed-color-fa6045);
    }

    .hasomed-button-icon,
    .hasomed-button-text
    {
        color: var(---ffffff-white);
    }
}

.hasomed-button-disabled
{
    /* UI Properties */
    background: transparent 0% 0% no-repeat padding-box;
    border: 1px solid var(---bbbdbe-greylight2);

    &:hover
    {
        /* UI Properties */
        background-color: transparent;
        cursor: default;
    }

    .hasomed-button-icon,
    .hasomed-button-text
    {
        color: var(---bbbdbe-greylight2);
    }
}

/* width */
::-webkit-scrollbar
{
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track
{
    background: var(---f4f4f4-greylight4);
}

/* Handle */
::-webkit-scrollbar-thumb
{
    background: var(---bbbdbe-greylight2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
{
    background: var(---575858-greystd);
}

:root
{

    /* Colors: */
    ---000000-black: #000000;
    ---3e3f3f-greydark: #3E3F3F;
    ---575858-greystd: #575858;
    ---9d9e9e-greylight1: #9D9E9E;
    ---bbbdbe-greylight2: #BBBDBE;
    ---ececec-greylight3: #ECECEC;
    ---f4f4f4-greylight4: #F4F4F4;
    ---fafafa-greylight5: #FAFAFA;
    ---ffffff-white: #FFFFFF;
    ---01a0ae-turquoise: #01A0AE;
    ---2567ce-azure: #2567CE;
    ---a23b72-redviolet: #A23B72;
    ---e96470-coral: #E96470;
    ---f6a500-yellowdark: #F6A500;
    ---ccecef-turquoiselight: #CCECEF;
    ---d3e1f5-azurelight: #D3E1F5;
    ---ecd8e3-redvioletlight: #ECD8E3;
    ---fbe0e2-corallight: #FBE0E2;
    ---fdedcc-yellowlight: #FDEDCC;
    ---6e9614-greendark: #6E9614;
    ---7fad18-greenstd: #7FAD18;
    ---e5efd1-greenlight: #E5EFD1;
    ---1c53f3-bluestd: #1c53f3;
    ---3766f3-bluelight: #3766f3;
    ---00dec7-tuerkis: #00dec7;
    ---d26405-orangedark: #D26405;
    ---f08a00-orangestd: #F08A00;
    ---ff9833-orangelight1: #FF9833;
    ---fad7b8-orangelight2: #FAD7B8;
    --unnamed-color-d32405: #D32405;
    ---f93816-redstd: #F93816;
    --unnamed-color-fa6045: #FA6045;
    ---ffefec-redlight2: #FFEFEC;
    ---bd10e0-hints: #BD10E0;
    --unnamed-color-707172: #707172;

    /* Font/text values */
    --unnamed-font-family-roboto: Roboto;
    --unnamed-font-family-robotocondensed-regular: RobotoCondensed-Regular;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-size-14: 14px;
    --unnamed-font-size-16: 16px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-30: 30px;
    --unnamed-font-size-120: 120px;
    --unnamed-font-size-14-220000267028809: 14.220000267028809px;
    --unnamed-font-size-15-75: 15.75px;
    --unnamed-font-size-12-44: 12.44px;
    --unnamed-font-size-17-719999313354492: 17.719999313354492px;
    --unnamed-font-size-20-25: 20.25px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-line-spacing-15: 15px;
    --unnamed-line-spacing-19: 19px;
    --unnamed-line-spacing-21: 21px;
    --unnamed-line-spacing-22: 22px;
    --unnamed-line-spacing-23: 23px;
    --unnamed-line-spacing-24: 24px;
    --unnamed-line-spacing-26: 26px;
    --unnamed-line-spacing-35: 35px;
    --unnamed-line-spacing-37: 37px;
    --unnamed-text-transform-uppercase: uppercase;

    // ---- Schatten ----
    --headerdropdown: 0 2 4 rgba(0, 0, 0, 0.15);
    --footer: 0 -2 4 rgba(0, 0, 0, 0.15);
    --menubar: 5 0 10 rgba(0, 0, 0, 0.05);
    --sidebar: 0 0 10 rgba(0, 0, 0, 0.15);
    --dialog: 0 3 10 rgba(0, 0, 0, 0.15);
    --headerdialog: 0 3 6 rgba(0, 0, 0, 0.15);
}

/* Character Styles */
.l-m-h4-menu-14_medium
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-21);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.l-m-h3-15-75pt_regular
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-15-75);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.l-m-small-12-44pt_regular
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-12-44);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.l-m-buttons-body-14pt_regular
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-21);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.l-m-h2-15-75pt_bold
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-15-75);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.l-m-h1-17-72pt_bold
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-17-719999313354492);
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.xl-small-14-22pt_regular
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-14-220000267028809);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.xl-buttons-body-16pt_regular
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.xl-h3-18pt_regular
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-35);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.xl-h4-menu-16pt_medium
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.xl-h2-18pt_bold
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.xl-h1-20-25pt_bold
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-20-25);
    line-height: var(--unnamed-line-spacing-26);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}

.styleguide-subhints
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-15);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---bbbdbe-greylight2);
    text-transform: var(--unnamed-text-transform-uppercase);
}

.sytleguide-hints
{
    font-family: var(--unnamed-font-family-robotocondensed-regular);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-19);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---bd10e0-hints);
}

.styleguide-artboard
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-120);
    line-height: var(--unnamed-line-spacing-37);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---ffffff-white);
}

.styleguide-überschriften
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-30);
    line-height: var(--unnamed-line-spacing-37);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707172);
}

.styleguide-bodytext
{
    font-family: var(--unnamed-font-family-roboto);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-23);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---575858-greystd);
}